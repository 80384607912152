<template>
	<div
		class="bubble"
		:style="style"></div>
</template>

<script setup lang="ts">
	const props = defineProps({
		width: {
			type: String,
			required: true,
			default: "100px",
		},
		height: {
			type: String,
			required: true,
			default: "100px",
		},
		left: {
			type: String,
			required: false,
			default: "auto",
		},
		right: {
			type: String,
			required: false,
			default: "auto",
		},
		top: {
			type: String,
			required: false,
			default: "auto",
		},
		bottom: {
			type: String,
			required: false,
			default: "auto",
		},
		colorGrad: {
			type: String,
			required: false,
			default: "#FF4545, #D40000",
		},
		directions: {
			type: Number,
			required: false,
			default: 0,
		},
	});

	const initStyles = () => ({
		"width": props.width,
		"height": props.height,
		"right": props.right,
		"left": props.left,
		"bottom": props.bottom,
		"top": props.top,
		"background": `linear-gradient(${props.directions}deg, ${props.colorGrad});`,
		"border-radius": "100%",
	});

	const style = ref(initStyles());

	watch(
		() => props,
		() => {
			style.value = initStyles();
		},
		{ deep: true },
	);
</script>

<style scoped lang="scss">
	@import "bubble";
</style>
